import { Suspense } from 'react';
import { getEcState } from '@packages/shared/src/utils/ecState/ecState';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import type { PageTemplate } from '@packages/recommendations';
import { useCookieConsentCategoryManager } from '../../hooks/useCookieConsentCategoryManager';
import { getAllAudiences } from '../../utils/audiences';
import type { Distance, ParagraphLibraryType, ParagraphType } from '../../../interfaces/components';
import { ComponentTypes } from '../../../interfaces/components';
import { createFilterForCurrentAudiences } from '../../utils/filterResult';
import { getComponent } from './componentRegistry';
import { useFilterTimeControl } from '../../hooks/useFilterTimeControl';

const styleDistance = (distance?: Distance) => ({
  ...(distance === 'variation-1' && {
    paddingInline: { xs: 3, lg: 0 },
  }),
  ...(distance === 'fullwidth' && {
    width: '100vw',
    left: '50%',
    transform: 'translate(-50vw, 0)',
    position: 'relative',
  }),
});

type ComponentRecognizerProps = {
  item: ParagraphType;
  pageTemplate?: PageTemplate;
  recoIndex?: number;
  token?: string;
  testDate?: string;
};

export const ComponentRecognizer = ({
  /** The data for the single paragraph you want to render */
  item,
  /** current page you are on (needed for tracking purposes) */
  pageTemplate,
  /** For tracking: if this component is a Reco, please specify which reco it is on the page (starting with 0) */
  recoIndex = 0,
  token,
  testDate,
}: ComponentRecognizerProps) => {
  const { getCookies } = useCookies();
  const cookies = getCookies();
  const audiences = getAllAudiences(getEcState(cookies));
  const filterTime = useFilterTimeControl(testDate);

  const filters = [createFilterForCurrentAudiences(audiences), filterTime];
  const showContent = filters.every((filter) => filter(item));

  if (showContent) {
    const Component = getComponent(item.type);
    if (Component) {
      return (
        <Component
          data={item}
          sx={{
            ...('field_distance' in item &&
              (item.field_distance === 'variation-1' || item.field_distance === 'fullwidth') &&
              styleDistance(item.field_distance)),
          }}
          pageTemplate={pageTemplate}
          recoIndex={recoIndex}
          token={token}
          key={item.id}
        />
      );
    }

    switch (item.type) {
      case ComponentTypes.FromLibrary: {
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {(item as ParagraphLibraryType)?.paragraphs_library_item?.map((libItem) =>
              libItem?.paragraph?.map((libParagraphItem: ParagraphType) => (
                <ComponentRecognizer key={item.id} item={libParagraphItem} token={token} />
              )),
            )}
          </>
        );
      }
      default:
        return null;
    }
  }
  return null;
};

/**
 * Renders an array of cms paragraphs using the correct components from the component recognizer
 */
export const CmsComponents = ({
  paragraphs = [],
  pageTemplate,
  token,
}: {
  /** array of all paragraphs that are to be rendered */
  paragraphs: ParagraphType[];
  /** current page you are on (needed for tracking purposes) */
  pageTemplate: PageTemplate;
  token?: string;
}) => {
  let recoIndex = 0;
  useCookieConsentCategoryManager();

  return (
    <>
      {paragraphs.map((p) => (
        <Suspense key={p.id}>
          <ComponentRecognizer
            item={p}
            pageTemplate={pageTemplate}
            // if this paragraph is a recoslider, pass the reco index and increase it after that
            // eslint-disable-next-line no-plusplus
            {...(p.type === ComponentTypes.RecoPrudsys && { recoIndex: recoIndex++ })}
            token={token}
          />
        </Suspense>
      ))}
    </>
  );
};
