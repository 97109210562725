import { useEffect, useRef, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNotification } from '@packages/shared/src/providers/NotificationProvider/useNotification';

/**
 * NOTE: This component is designed for components that include
 * HTML with dangerouslySetInnerHTML and contain CSS selectors
 * such as `<button class="agree-cookie-consent-category-*" />`.
 */

type OneTrust = {
  UpdateConsent: (category: string, value: string) => void;
};

type ConsentCategories = { [s: string]: string };

type HandleClick = (event: any, callbackReject?: () => void, callbackApproval?: () => void) => void;

declare global {
  interface Window {
    OneTrust?: OneTrust;
  }
}

const consentCategoriesIdList = ['C0001', 'C0002', 'C0003', 'C0004', 'C0005', 'C0006'];

const consentMessages = defineMessages({
  rejectSuccessful: {
    id: 'consent.revocation.category.success',
    defaultMessage: 'Der Widerruf war erfolgreich.',
  },
  approvalSuccessful: {
    id: 'consent.approval.category.success',
    defaultMessage: 'Zustimmung erfolgreich durchgeführt.',
  },
});

/**
 * CSS Selector: OneTrust Consent Value (C0001:0 = Disable Category 1, C0001:1 = Enable Category 1)
 *
 * @constant
 * @type {ConsentCategories}
 */
const consentCategories: ConsentCategories = consentCategoriesIdList.reduce((acc, id) => {
  acc[`reject-cookie-consent-category-${id.toLowerCase()}`] = `${id}:0`;
  acc[`agree-cookie-consent-category-${id.toLowerCase()}`] = `${id}:1`;
  return acc;
}, {} as ConsentCategories);

/**
 * Extracts CSS classes from the consentCategories object and combines them into a single selector.
 *
 * @returns {string} A combined CSS selector string.
 */
const combinedSelector = Object.keys(consentCategories)
  .map((selector) => `.${selector}`)
  .join(', ');

/**
 * Updates the consent status based on the class name of the clicked element.
 *
 * @param event - The click event triggered by the user.
 * @param [callbackReject] - Optional callback function to execute if the consent is rejected.
 * @param [callbackApproval] - Optional callback function to execute if the consent is agreed.
 */
const handleClick: HandleClick = (event, callbackReject, callbackApproval) => {
  event.preventDefault();

  const className = event.target.className as keyof typeof consentCategories;
  const consentValue = consentCategories[className];

  if (consentValue && window?.OneTrust?.UpdateConsent) {
    window.OneTrust.UpdateConsent('Category', consentValue);

    if (callbackReject && consentValue.endsWith(':0')) {
      callbackReject();
    }

    if (callbackApproval && consentValue.endsWith(':1')) {
      callbackApproval();
    }
  }
};

/**
 * Component to manage cookie consent categories using the OneTrust API.
 * It listens for click events on elements with specific CSS classes and updates
 * the consent status accordingly. Displays a notification upon successful consent revocation.
 *
 * NOTE: In cases where the component can be rendered multiple times on the same page,
 * it's crucial to ensure that event binding occurs only once per selector.
 * Uses dataset to track if an event listener has already been added to an element,
 * preventing duplicate listeners and ensuring the click handler is only added once per element.
 *
 * @see {@link https://developer.onetrust.com/onetrust/docs/javascript-api#update-consent | OneTrust API Documentation}
 */
export const useCookieConsentCategoryManager = () => {
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();

  const callbackReject = useCallback(() => {
    addNotification({
      severity: 'success',
      message: formatMessage(consentMessages.rejectSuccessful),
      duration: 2500,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage]);

  const callbackApproval = useCallback(() => {
    addNotification({
      severity: 'success',
      message: formatMessage(consentMessages.approvalSuccessful),
      duration: 2500,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage]);

  const clickHandlerRef = useRef<HandleClick>(undefined);

  // Store the handler in a ref to keep the same function reference
  // across renders for consistent add/remove event listener behavior.
  clickHandlerRef.current = useCallback(
    (event: any) => handleClick(event, callbackReject, callbackApproval),
    [callbackReject, callbackApproval],
  );

  useEffect(() => {
    const innerHtmlElements = document.querySelectorAll(combinedSelector);
    innerHtmlElements.forEach((element) => {
      const htmlElement = element as HTMLElement;
      if (!htmlElement.dataset.clickHandlerBound) {
        htmlElement.addEventListener('click', clickHandlerRef.current!);
        htmlElement.dataset.clickHandlerBound = 'true';
      }
    });

    // cleanup the event listeners when the component unmounts or updates
    return () => {
      innerHtmlElements.forEach((element) => {
        const htmlElement = element as HTMLElement;
        htmlElement.removeEventListener('click', clickHandlerRef.current!);
        delete htmlElement.dataset.clickHandlerBound;
      });
    };
  }, [callbackReject, callbackApproval]);
};
